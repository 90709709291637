import React, {useState} from 'react';
import "../styles/style.css";
import "../styles/newstyle.css";
import useForm from "../hooks/useForm.js"
import Input from "./base/Input.js"
import File from "./base/File.js"
import ToggleSwitch from "./base/toggle"
import TextArea from "./base/TextArea.js"
import {fileType, posterFileType} from "../config"
import FormV2 from  "./FormV2.js"

const Form = ()=> {
    const {data, handleChange, sendFile, onSubmit, isWorking, uploadingFile,  uploadingPoster,
        sendPoster, uploadUnlockableContent} = useForm({});
    const [toggle, setToggle] = useState(false);
    const [unlockable, setUnlokable] = useState([1]);


    async function onChange(e) {
        const file = e.target.files[0]
        await sendFile(file)
    }

    async function onChangePoster(e) {
        const file = e.target.files[0]
        await sendPoster(file)
    }

    const uploadUnlockableContentImages = async (e) => {
        const file = e.target.files[0]
        console.log("file: ", file)
        await uploadUnlockableContent(file)
    }

    const setNewUnlockable = () => {
        setUnlokable([...unlockable, unlockable.length + 1])
    }

    console.log("data: ", JSON.stringify(data))

    return (
        <>
            <div className="container">
                <div className="row">
                    <div
                        className="col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3 col-lg-6 col-lg-offset-3 no-padding forms">
                        <h1>Create {toggle? "Token": "NFT"}</h1>
                        <div style={{textAlign: 'center', display: 'flex',justifyContent: 'center', alignItems: 'center'}}> Market
                            <ToggleSwitch toggle={toggle} setToggle={setToggle} /> Token</div>
                        {
                            toggle? <FormV2/>:
                                <div className="form-content">
                                    <div name="contactForm" id="contactForm">
                                        <div className="form-group ">
                                            <Input value={data["name"]}
                                                   label="Name" name={"name"} type="text"
                                                   handleChange={handleChange}
                                            />
                                        </div>

                                        <div className="form-group ">
                                            <Input value={data["price"]}
                                                   label="Price" name={"price"} type="text"
                                                   handleChange={handleChange}
                                            />
                                        </div>

                                        <div className="form-group ">
                                            <Input value={data["styleId"]}
                                                   label="Style Id" name={"styleId"} type="text"
                                                   handleChange={handleChange}
                                            />
                                        </div>

                                        <div className="form-group ">
                                            <Input value={data["manufacturer"]}
                                                   label="Manufacturer" name={"manufacturer"} type="text"
                                                   handleChange={handleChange}
                                            />
                                        </div>

                                        <div className="form-group ">
                                            <Input value={data["nickName"]}
                                                   label="NickName" name={"nickName"} type="text"
                                                   handleChange={handleChange}
                                            />
                                        </div>

                                        <div className="form-group ">
                                            <Input value={data["colorWay"]}
                                                   label="ColorWay" name={"colorWay"} type="text"
                                                   handleChange={handleChange}
                                            />
                                        </div>


                                        <div className="form-group ">
                                            <Input value={data["model"]}
                                                   label="Model" name={"model"} type="text"
                                                   handleChange={handleChange}
                                            />
                                        </div>


                                        <div className="form-group ">
                                            <Input value={data["size"]}
                                                   label="Size" name={"size"} type="text"
                                                   handleChange={handleChange}
                                            />
                                        </div>


                                        <div className="form-group ">
                                            <TextArea value={data["description"]}
                                                      label="Description" name={"description"}
                                                      handleChange={handleChange}
                                            />
                                        </div>

                                        <div className="form-group ">
                                            <File accept={posterFileType}
                                                  label={uploadingPoster? "Uploading Poster...": "Upload your Poster"} name={"poster"} type="file"
                                                  handleChange={onChangePoster}
                                            />
                                        </div>

                                        <div className="form-group ">
                                            <File accept={fileType}
                                                  label={uploadingFile? "Uploading Video...": "Upload your File"} name={"file"} type="file"
                                                  handleChange={onChange}
                                            />
                                        </div>
                                        <div className="form-group ">
                                            {unlockable.map((value)=> {
                                                return (
                                                    <File accept={`${posterFileType},${fileType}`}
                                                          label={ `Upload your Unlockable content ${value}`} name={`unlock`} type="file"
                                                          handleChange={uploadUnlockableContentImages}
                                                    />)
                                            })}

                                            <div>
                                                <button className="btn btn-danger" onClick={()=> setNewUnlockable()}>
                                                    More unlockable
                                                </button></div>
                                        </div>
                                        <button type="submit" name="submit" disabled={isWorking} onClick={onSubmit}
                                                className="btn x20 btn-lg x20 btn-primary x20 btn-block button-opentype"
                                                style={{backgroundColor: "#66ccff"}}>
                                            {isWorking ? "Minting..." : "Mint"}
                                        </button>
                                    </div>

                                </div>
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default Form;
