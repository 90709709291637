import React from 'react';
import "../styles/style.css";
import "../styles/newstyle.css";
import Layout from "../layout/Layout.js"
import Form from "../components/Form.js"

const Create = ()=> {
    return (
       <Layout>
           <div style={{minHeight: '100%'}}>
               <Form/>
           </div>
       </Layout>
    );
}

export default Create;
