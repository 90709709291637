import React, {useContext, useState, useEffect} from 'react';
import "../styles/style.css";
import "../styles/newstyle.css";
import ButtonMenuMobile from "./DrawerMobile"
import { useEthers } from "@usedapp/core";
import {OwnerContext} from "../providers/OwnerProvider";

const Header = ()=> {
    const [isVisible, setVisible] = useState(false);
    const {getMyRole, myAccount} = useContext(OwnerContext);

    const {activateBrowserWallet, account} = useEthers();

    useEffect(async ()=>{
        if(account){
            await getMyRole(account)
        } else {
            activateBrowserWallet();
        }
        // setMyAccount({address: account});
    },[account]);


   // const etherBalance = useEtherBalance(account);

    const handleWallet = () => {
        activateBrowserWallet();
    }

    const toggleOpenMenu = () => setVisible(!isVisible);
    return (
        <header className="app-header">
            <nav className="navbar navbar-inverse" role="navigation">
                <div className="container">
                    <div className="new-nav">
                        <div className="navbar-header">
                            <a className="navbar-brand" href="/">
                                <img data-reveal="fadeInLeft" data-reveal-delay="500"
                                     src="https://sneaker.imgix.net/https%3A%2F%2Ftrusted-trader-bucket.s3.amazonaws.com%2Fpublic%2Falwayslegit-logo-blue.png?w=320&auto=compress&s=c1f8a6b7e7d9d422c63a69c89e6bc7eb"
                                     title="Always Legit"/>
                            </a>
                        </div>

                        <div className="menu-action-btn" style={{display: 'flex', justifyContent: 'space-between'}}>
                            <div>
                                <button type="button" id="connectButton" style={{display: "inline-block"}} onClick={handleWallet}>
                                    <img src="https://sneaker.imgix.net/https%3A%2F%2Ftrusted-trader-bucket.s3.amazonaws.com%2Fpublic%2Fmetamask-icon-2728406-2261817.png?w=30&amp;h=30&amp;s=82733da375c54283b5ca520c8ff59c3a"
                                         style={{paddingRight: "10px"}}/>
                                    {!account ? 'Connect Wallet' : account}</button>
                            </div>
                        <ButtonMenuMobile
            toggleMenu={toggleOpenMenu}
            isVisible={isVisible}
            account={myAccount}
            scrollGarden={() => {
              toggleOpenMenu();
            }}

          />
                        </div>
                    </div>
                </div>
            </nav>
        </header>
    );
}

export default Header;

