import React, {useContext, useEffect} from 'react';
import "../styles/style.css";
import "../styles/newstyle.css";
import MainScreen from "../components/MainScreen"
import Layout from "../layout/Layout.js"
import {DataContext} from "../providers/MainProvider";

const Home = ()=> {
    const {nfts, fetchOnSales, isWorking} = useContext(DataContext);
    console.log("nfts", nfts)
    useEffect(async () => {
        await fetchOnSales();
        // eslint-disable-next-line
    },[])
    return (
       <Layout>
                <MainScreen me={false} nfts={nfts}/>
       </Layout>
    );
}

export default Home;
