import React, {useState} from 'react';
import "../styles/style.css";
import "../styles/newstyle.css";
import useForm from "../hooks/useFormV2.js"
import Input from "./base/Input.js"
import File from "./base/File.js"
import {fileType, posterFileType} from "../config"
import TextArea from "./base/TextArea";

const FormV2 = ()=> {
    const {data, handleChange, sendFile, onSubmit, isWorking, uploadingFile,  uploadingPoster,
        sendPoster} = useForm({});

    async function onChange(e) {
        const file = e.target.files[0]
        await sendFile(file)
    }

    async function onChangePoster(e) {
        const file = e.target.files[0]
        await sendPoster(file)
    }

    console.log("data: ", data)

    return (
        <>
            <div className="form-content">
                <div name="contactForm" id="contactForm">
                    <div className="form-group ">
                        <Input value={data["name"]}
                               label="Name" name={"name"} type="text"
                               handleChange={handleChange}
                        />
                    </div>

                    <div className="form-group ">
                        <Input value={data["price"]}
                               label="Price" name={"price"} type="text"
                               handleChange={handleChange}
                        />
                    </div>

                    <div className="form-group ">
                        <Input value={data["quantity"]}
                               label="Quantity" name={"quantity"} type="text"
                               handleChange={handleChange}
                        />
                    </div>

                    <div className="form-group ">
                        <TextArea value={data["description"]}
                                  label="Description" name={"description"}
                                  handleChange={handleChange}
                        />
                    </div>

                    <div className="form-group ">
                        <File accept={posterFileType}
                              label={uploadingPoster? "Uploading Poster...": "Upload your Poster"} name={"poster"} type="file"
                              handleChange={onChangePoster}
                        />
                    </div>

                    <div className="form-group ">
                        <File accept={fileType}
                              label={uploadingFile? "Uploading Video...": "Upload your File"} name={"file"} type="file"
                              handleChange={onChange}
                        />
                    </div>
                    <button type="submit" name="submit" disabled={isWorking} onClick={onSubmit}
                            className="btn x20 btn-lg x20 btn-primary x20 btn-block button-opentype"
                            style={{backgroundColor: "#66ccff"}}>
                        {isWorking ? "Minting..." : "Mint"}
                    </button>
                </div>

            </div>
        </>
    );
}

export default FormV2;
