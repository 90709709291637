import React, {useContext, useState} from 'react';
import "../styles/style.css";
import "../styles/newstyle.css";
import {DataContext} from "../providers/MainProvider";
import {Link} from "react-router-dom";

const Main = ({nfts, me= false})=> {
    const {buyNFT, isBuying} = useContext(DataContext)
    const [itemSelectedId, setItemSelectedId] = useState(null);
    const buy = (id, price)=> {
        if(isBuying) return;
        setItemSelectedId(id);
        buyNFT(id, price);
    }
    const values = nfts.filter(item => item.hasOwnProperty("normalData"));
    return (
        <main className="content-wrapper" style={{minHeight: '100%'}}>
           <div className={"container weeb3"}>
               <div className="listing-list-raffle" style={{padding: "0 2%"}}>
                   <div style={{textAlign: "center", margin: "10px 0"}}>
                       <button type="button" id="connectButton"></button>
                   </div>
                   <div>
                   </div>
                   <div className="calendar-raffle dashboard">
                       <div className="release-container droplist-container">


                           {values.map(({normalData: item, id, price})=> {
                               return (
                                   <div className="number-pic pos-rel" key={item.id}>
                                       <a style={{backgroundColor: "white"}}>
                                           <div className="videoContainar">
                                               <video className="my-video2" style={{display:"block", width: "100%"}} rel="prefetch" playsInline
                                                      autoPlay={true} loop={true} muted={true} preload="metadata"
                                                      poster={item.image? item.image: item.image? item.image : "https://www.sneakerfiles.com/wp-content/uploads/2021/07/travis-scott-fragment-air-jordan-1-DH3227-105-release-info-price.jpeg"}>
                                                   <source
                                                       src={item.animation_url}
                                                       type="video/mp4"/>
                                               </video>
                                           </div>
                                       </a>
                                       <div className="info">
                                           <a>
                                               <div className="product-info newWEb3">
                                                   {item.name && (<h5 className="newH5">{item.name}</h5>)}
                                                   <Link to={`/detail/${id}`}>
                                                   <h5 className="newH5">Details</h5>
                                                   </Link>
                                                   <h5 className="newH5">Size <br/>{item.size? item.size: 10}</h5>
                                                   <h5 className="newH5">
                                                       <img
                                                       className='logoPrice'
                                                       src="https://sneaker.imgix.net/https%3A%2F%2Ftrusted-trader-bucket.s3.amazonaws.com%2Fpublic%2Feth-1200px-Ethereum-icon-purple.svg.png?w=30&h=30&s=d9dfe35b24c7fe989b8b49bc0157d279"
                                                       style={{width:"20px", height:"20px"}}/>{price.length > 5? item.price.substring(0, 5): price}</h5>
                                                   {
                                                       !me && ( <h5 className="newH5" onClick={()=> buy(id, price)}>
                                                           <img
                                                               className='logoPrice'
                                                               src="https://sneaker.imgix.net/https%3A%2F%2Ftrusted-trader-bucket.s3.amazonaws.com%2Fpublic%2Fusd-coin-usdc-logo.png?w=30&h=30&s=f7d83e1d7a68e7c92aa2a25585c3876a"
                                                               width="20" height="20"/> {isBuying && itemSelectedId === id? "Buying...": "Buy"}</h5>)
                                                   }
                                               </div>
                                           </a>
                                       </div>
                                   </div>
                               )
                           })}

                       </div>
                   </div>
               </div>
           </div>
        </main>
    );
}

export default Main;
