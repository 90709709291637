import React,{ createContext, useState } from 'react';
import useMarket from "../hooks/useMarket.js"
import OwnerProvider from "./OwnerProvider"
export const DataContext = createContext(null);

const MainProvider = ({children}) =>{
    const {
        mint,
        buyNFT, uploadFileToIPFS, isWorking, nfts, fetchOnSales, fetchMyNFT, fetchData,
         findItem,
        nftDetail, myNFTS, myNftsData, isBuying, mintToken, fetchTokens, tokens, transferNFT, isTransfer, transferToken, getAll, isLoading} = useMarket();


    return (
        <DataContext.Provider value={{ mint, buyNFT, uploadFileToIPFS, isWorking, nfts, fetchOnSales, fetchMyNFT,
            fetchData, findItem,
            nftDetail, myNFTS, myNftsData, isBuying, mintToken, fetchTokens, tokens, transferNFT, isTransfer, transferToken, getAll, isLoading}}>
            <OwnerProvider>
            {children}
            </OwnerProvider>
        </DataContext.Provider>
    );
}

export default MainProvider;
