import React,{ createContext, useState } from 'react';
import useOwner from "../hooks/useOwner"

export const OwnerContext = createContext(null);

const OwnerProvider = ({children}) =>{
    const {
        isWorking,
        addMinter,
        remove,
        addAdmin,
        fetchRoles,
        accounts,
        myAccount, setMyAccount, getMyRole,  isRemoving, isSaving} = useOwner();


    return (
        <OwnerContext.Provider value={{       isWorking,
            addMinter,
            remove,
            addAdmin,
            fetchRoles,
            accounts,
            myAccount, setMyAccount, getMyRole,  isRemoving, isSaving}}>
            {children}
        </OwnerContext.Provider>
    );
}

export default OwnerProvider;
