import React from 'react';
import "../styles/style.css";
import "../styles/newstyle.css";

const Push = ()=> {
    return (
        <>
        <div>
        <div id="push"></div>
        <div className="black-overlay"></div>
        </div>
    <div id="liveVisitorsPopup">
        <p><span className="vis-closePop">X</span></p>

        <div className="vis-headerPop">
            <img src="https://sneaker.imgix.net/https%3A%2F%2Ftrusted-trader-bucket.s3.amazonaws.com%2Fpublic%2Flive.png?w=200&auto=compress&s=da640a3704c453d5ab0d010c3c0b38b7" width="40px" height="40px"/>
                <span className="vis-popText">Current users on this page</span> <span className="vis-count">5</span>
        </div>
    </div>
        </>
    );
}

export default Push;
