import Drawer from 'react-modern-drawer';
import 'react-modern-drawer/dist/index.css';
import {Link} from "react-router-dom";

const ButtonMenuMobile = ({ toggleMenu, isVisible, account }) => {

  return (<>
    <button className={'button-opentype '} onClick={toggleMenu}>
      Open
    </button>

    <Drawer open={isVisible} onClose={toggleMenu} direction="right">
      <div className="h-full w-full overflow-y-auto flex flex-col gap-4 p-8">
        <div className="flex flex-col items-center">
        <div className="navbar-header">
                            <a className="navbar-brand" href="/">
                                <img data-reveal="fadeInLeft" data-reveal-delay="500" style={{width: '100%', height: '100%'}}
                                     src="https://sneaker.imgix.net/https%3A%2F%2Ftrusted-trader-bucket.s3.amazonaws.com%2Fpublic%2Falwayslegit-logo-blue.png?w=320&auto=compress&s=c1f8a6b7e7d9d422c63a69c89e6bc7eb"
                                     title="Always Legit"/>
                            </a>
                        </div>
          {
            account && account.role !== "GUEST" && (
          <div className="text-center">
              <Link to="/create">
            <button className={'button-opentype'}>Create</button>
              </Link>
          </div>)}
          {
            account && account.role === "ADMIN" && (
                <div className="text-center">
              <Link to="/admin">
                <button className={'button-opentype'}>Admin</button>
              </Link>
            </div>)
          }

          {
            account && account.role === "ADMIN" && (
                <div className="text-center">
                  <Link to="/transfer">
                    <button className={'button-opentype'}>Transfer</button>
                  </Link>
                </div>)
          }

          <div className="text-center">
          <Link to="/me">
            <button className={'button-opentype'}>My NFTS</button>
              </Link>
          </div>
        </div>
      </div>
    </Drawer>
  </>
)}

export default ButtonMenuMobile
