import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import MainProvider from "./providers/MainProvider";
import Me from "./pages/Me"
import Create from "./pages/Create"
import Admin from "./pages/Admin"
import Transfer from "./pages/Transfer"
import DetailPage from "./pages/Detail"
import AdminRoute from "./routes/AdminRoutes"
import MinterRoute from "./routes/MinterRoutes"
//dapp
import { DAppProvider } from "@usedapp/core";

ReactDOM.render(
    <BrowserRouter>
        <MainProvider>
            <Routes>
                <Route path="/" element={<DAppProvider config={{}}><Home /></DAppProvider>} />
                <Route path="/me" element={<DAppProvider config={{}}><Me /></DAppProvider>} />
                <Route path="/create" element={<DAppProvider config={{}}>
                    <MinterRoute>
                    <Create />
                </MinterRoute></DAppProvider>} />
                <Route path="/admin" element={<DAppProvider config={{}}>
                    <AdminRoute>
                        <Admin />
                    </AdminRoute>
                </DAppProvider>} />

                <Route path="/transfer" element={<DAppProvider config={{}}>
                    <AdminRoute>
                        <Transfer />
                    </AdminRoute>
                </DAppProvider>} />

                <Route path="/detail/:id" element={<DAppProvider config={{}}><DetailPage /></DAppProvider>} />
            </Routes>
        </MainProvider>
    </BrowserRouter>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
