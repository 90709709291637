import React, {useState}  from "react";
import {useWorkingIndicator} from "./useBooleanToggler"
import {ethers} from "ethers";
import Web3Modal from "web3modal";
import {nftMarket, nftAddress, GRAPH_URL, nft1155Adress} from "../config";
import NFT from "../artifacts/contracts/Nft.sol/NFT.json";
import Market from "../artifacts/contracts/Marketplace.sol/NFTMarket.json";
import Token from "../artifacts/contracts/NFT1155.sol/NFT1155.json";
import { createClient } from 'urql';
const graphClient = createClient({
    url: GRAPH_URL
});
const useOwner = () => {
    const {isWorking, startWork, finishWork} = useWorkingIndicator();
    const [accounts, setAccounts] = useState([]);
    const [myAccount, setMyAccount] = useState(null);
    const [isRemoving, setIsRemoving] = useState(false);
    const [isSaving, setIsSaving] = useState(false);


    const addMinter = async (address) => {
        setIsSaving(true)
        try{
            const web3Modal = new Web3Modal()
            const connection = await web3Modal.connect()
            const provider = new ethers.providers.Web3Provider(connection)
            const signer = provider.getSigner()
            const tokenContract = new ethers.Contract(nftAddress, NFT.abi, signer)
            const contract = new ethers.Contract(nftMarket, Market.abi, signer)
            const token = new ethers.Contract(nft1155Adress, Token.abi, signer)


            const addedToken = await tokenContract.addMinter(address)
            await addedToken.wait();

            const addedMarket = await contract.addMinter(address)
            await addedMarket.wait();

            const addedTokenD = await token.addMinter(address)
            await addedTokenD.wait();
            await fetchRoles()

            console.log(`addedToken minter: ${addedToken}, addedMarket minter: ${addedMarket}`)
        } catch(err){
            if (err.data && err.data.message) {
                window.alert("Failed to create NFT: " + err.data.message);
                return null;
            }
        }
        setIsSaving(false)
    }

    const addAdmin = async (address) => {
        setIsSaving(true)
        try{
            const web3Modal = new Web3Modal()
            const connection = await web3Modal.connect()
            const provider = new ethers.providers.Web3Provider(connection)
            const signer = provider.getSigner()
            const tokenContract = new ethers.Contract(nftAddress, NFT.abi, signer)
            const contract = new ethers.Contract(nftMarket, Market.abi, signer);
            const token = new ethers.Contract(nft1155Adress, Token.abi, signer)

            const addedToken = await tokenContract.addAdmin(address)
            await addedToken.wait();

            const addedMarket = await contract.addAdmin(address)
            await addedMarket.wait();

            const addedTokenD = await token.addAdmin(address)
            await addedTokenD.wait();

            await fetchRoles();
            console.log(`addedToken admin: ${addedToken}, addedMarket admin: ${addedMarket}`)
        } catch(err){
            if (err.data && err.data.message) {
                window.alert("Failed to create NFT: " + err.data.message);
                return null;
            }
        }

        setIsSaving(false)
    }

    const removeMinter = async (address) => {
        setIsRemoving(true)
        try{
            const web3Modal = new Web3Modal()
            const connection = await web3Modal.connect()
            const provider = new ethers.providers.Web3Provider(connection)
            const signer = provider.getSigner()
            const tokenContract = new ethers.Contract(nftAddress, NFT.abi, signer)
            const contract = new ethers.Contract(nftMarket, Market.abi, signer)
            const token = new ethers.Contract(nft1155Adress, Token.abi, signer)


            const addedToken = await tokenContract.removeMinter(address)
            await addedToken.wait();

            const addedMarket = await contract.removeMinter(address)
            await addedMarket.wait();

            const addedTokenD = await token.removeMinter(address)
            await addedTokenD.wait();

            console.log(`removeToken minter: ${addedToken}, removeMarket minter: ${addedMarket}`)

        } catch(err){
            if (err.data && err.data.message) {
                window.alert("Failed to remove role minter: " + err.data.message);
                return null;
            }
        }

        setIsRemoving(false)
    }


    const removeAdmin = async (address) => {
        setIsRemoving(true)
        try{
            const web3Modal = new Web3Modal()
            const connection = await web3Modal.connect()
            const provider = new ethers.providers.Web3Provider(connection)
            const signer = provider.getSigner()
            const tokenContract = new ethers.Contract(nftAddress, NFT.abi, signer)
            const contract = new ethers.Contract(nftMarket, Market.abi, signer)
            const token = new ethers.Contract(nft1155Adress, Token.abi, signer)

            const addedToken = await tokenContract.removeAdmin(address)
            await addedToken.wait();

            const addedMarket = await contract.removeAdmin(address)
            await addedMarket.wait();

            const addedTokenD = await token.removeAdmin(address)
            await addedTokenD.wait();


            console.log(`removeToken admin: ${addedToken}, removeMarket admin: ${addedMarket}`)

        } catch(err){
            if (err.data && err.data.message) {
                window.alert("Failed to remove role admin: " + err.data.message);
                return null;
            }
        }

        setIsRemoving(false)
    }

    const remove = async (account) => {
        console.log(`remove ${account.id}`)
        const {id, role = "MINTER"} = account
        if(role.toLowerCase() === "minter"){
            await removeMinter(id)
            await fetchRoles()
        } else if(role.toLowerCase() === "admin"){
            await removeAdmin(id)
            await fetchRoles()
        }

    }






    const fetchMyRoles = async (tokensQuery) =>{
        let res = await graphClient.query(tokensQuery).toPromise();
        if(res.data.accounts){
            let response = await Promise.all(res.data.accounts.filter(async account => {
                return {...account}
            }))

            return response

        } else {
            return res.data && res.data.account && res.data.account.role? res.data.account : {role: "GUEST"};
        }

    }



    const fetchRoles = async () => {
        const rolesQuery = `
         {
       accounts{
      id
      role
     }
   }
`
        startWork()
        const roles = await fetchMyRoles(rolesQuery);
        setAccounts(roles)
        finishWork();
    }


    const getMyRole = async (address= "")=> {
        const rolesQuery = `
         query {
         account(id: "${address.toLowerCase()}"){
         role
      }
  }
`

        startWork()
        const roles = await fetchMyRoles(rolesQuery);
        console.log("here", roles)
        setMyAccount({...roles, address})
        finishWork();
    }



    return {
        isWorking,
        addMinter,
        remove,
        addAdmin,
        fetchRoles,
        accounts,
        myAccount, setMyAccount,
        getMyRole, isRemoving, isSaving
    };
};

export default useOwner;
