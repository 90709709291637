import React, {useState}  from "react";
import {useWorkingIndicator} from "./useBooleanToggler"
import {ethers} from "ethers";
import Web3Modal from "web3modal";
import {create as ipfsHttpClient} from "ipfs-http-client";
import axios from "axios";
import {nftMarket, nftAddress,nft1155Adress, rpcEndpoint, GRAPH_URL} from "../config";
import NFT from "../artifacts/contracts/Nft.sol/NFT.json";
import Market from "../artifacts/contracts/Marketplace.sol/NFTMarket.json";
import Token from "../artifacts/contracts/NFT1155.sol/NFT1155.json";
import { createClient } from 'urql';
const client = ipfsHttpClient('https://ipfs.infura.io:5001/api/v0');

const graphClient = createClient({
    url: GRAPH_URL
});
const useMarket = () => {
    const {isWorking, startWork, finishWork} = useWorkingIndicator();
    const [data, setData] = useState([]);
    const [myNftsData, setMyNftsData] = useState([]);
    const [nftDetail, setNftDetail] = useState({});
    const [isBuying, setBuying] = useState(false)
    const [tokens, setTokens] = useState([]);
    const [isTransfer, setTransfer] = useState(false)
    const [isLoading, setLoading] = useState(false)

    const mint = async ({price, ...rest}) => {
        startWork()
        const data = JSON.stringify({...rest});
        try{
            const added = await client.add(data);
            const url =  `https://ipfs.infura.io/ipfs/${added.path}`;
            await createSale(url, price);

        }catch(error){
            console.log("error: ", error)
        }
    };

    const createSale = async (url, priceValue) => {
        try{
            const web3Modal = new Web3Modal()
            const connection = await web3Modal.connect()
            const provider = new ethers.providers.Web3Provider(connection)
            const signer = provider.getSigner()

            /* next, create the item */
            let contract = new ethers.Contract(nftAddress, NFT.abi, signer)
            let transaction = await contract.createToken(url)
            let tx = await transaction.wait()
            let event = tx.events[0]
            let value = event.args[2]
            let tokenId = value.toNumber()
            const price = ethers.utils.parseUnits(priceValue, 'ether')
            /* then list the item for sale on the marketplace */
            contract = new ethers.Contract(nftMarket, Market.abi, signer)
            let listingPrice = await contract.getListingPrice()
            listingPrice = listingPrice.toString()
            transaction = await contract.createMarketItem(nftAddress, tokenId, price, { value: listingPrice })
            await transaction.wait();
            await fetchOnSales()
            finishWork()
        } catch(err){
            if (err.data && err.data.message) {
                window.alert("Failed to create NFT: " + err.data.message)
            }
            finishWork()
        }

    }

    const uploadFileToIPFS = async (file) => {
        console.log("file: " + file)
        try {
            const added = await client.add(
                file,
                {
                    progress: (prog) => console.log(`received: ${prog}`)
                }
            )
            const url = `https://ipfs.infura.io/ipfs/${added.path}`
            return url
        } catch (error) {
            console.log('Error uploading file: ', error)
            return null
        }
    }


    const buyNFT = async (itemId, priceItem) => {

        setBuying(true)
        try {
            const web3Modal = new Web3Modal()
            const connection = await web3Modal.connect()
            const provider = new ethers.providers.Web3Provider(connection)
            const signer = provider.getSigner()
            const contract = new ethers.Contract(nftMarket, Market.abi, signer)

            const price = ethers.utils.parseUnits(priceItem.toString(), 'ether')
            const transaction = await contract.createMarketSale(nftAddress, itemId, {
                value: price
            })
            await transaction.wait();

            window.alert("Purchased successfully")
            await fetchOnSales();
        } catch (err){
            console.log(err.data)
            if (err.data && err.data.message) {
                window.alert("Failed to buy NFT: " + err.data.message)
            }
        }
        setBuying(false)
    }


    const transferToken = async (itemId, addresses) => {
        console.log("transferToken: ", itemId, addresses)
        setTransfer(true)
        try {
            const web3Modal = new Web3Modal()
            const connection = await web3Modal.connect()
            const provider = new ethers.providers.Web3Provider(connection)
            const signer = provider.getSigner()
            const contract = new ethers.Contract(nft1155Adress, Token.abi, signer)
           const arrayAd= addresses.replace(/ /g,'').split(",")
           const validAddres = [];
           for(let i=0; i<arrayAd.length; i++){
            let valid=ethers.utils.getAddress(arrayAd[i])
            validAddres.push(valid);
            }
            const transaction = await contract.transferBatch(itemId, validAddres)
            await transaction.wait();
            await fetchTokens();
        } catch (err){
            console.log(err)
            if (err.data && err.data.message) {
                window.alert("Failed to Transfer Token: " + err.data.message)
            }
        }
        setTransfer(false)
    }


    const transferNFT = async (itemId, address) => {
        setTransfer(true)
        try {
            const web3Modal = new Web3Modal()
            const connection = await web3Modal.connect()
            const provider = new ethers.providers.Web3Provider(connection)
            const signer = provider.getSigner()
            const contract = new ethers.Contract(nftMarket, Market.abi, signer)
            const transaction = await contract.transferShoes(nftAddress, itemId, address)
            await transaction.wait();
            await fetchOnSales();
        } catch (err){
            console.error(err)
            if (err.data && err.data.message) {
                window.alert("Failed to Transfer NFT: " + err.data.message)
            }
        }
        setTransfer(false)
    }


    const fetchTokens = async () => {
        try {
            const provider = new ethers.providers.JsonRpcProvider(rpcEndpoint)
            const tokenContract = new ethers.Contract(nft1155Adress, Token.abi, provider)
            const data = await tokenContract.fetchAvailablesTokens()

            const items = await Promise.all(data.map(async i => {
                console.log("i: ", i);
                const tokenUri = await tokenContract.uri(i.tokenId)
                const meta = await axios.get(tokenUri);
                let item = {
                    price: meta.data.price,
                    itemId: i.tokenId.toNumber(),
                    quantity: meta.data.quantity,
                    file: meta.data.file,
                    name: meta.data.name,
                    image: meta.data.image,
                }
                return item
            }));
            console.log("my items",  items)
            setTokens(items)
        } catch(err){
            console.log(err)
            setTokens([])
            window.alert("Failed fetch tokens: " + err.data.message)
        }

    }


    const fetchData = async (tokensQuery) =>{
        let res = await graphClient.query(tokensQuery).toPromise();
        if(res.data.shoes){
            let response = await Promise.all(res.data.shoes.map(async token => {
                const metadataURI = token.token.contentURI || ""
               // console.log("data: " + JSON.stringify(token.token.contentURI))
                let meta = {};
                try {
                    const metaData = await fetch(metadataURI)
                    token.price = ethers.utils.formatUnits(token.price.toString(), 'ether')
                    let response = await metaData.json() || {}
                    meta = response
                } catch (err) {
                }
                return {...token, ...meta}
            }))
            return response
        } else {
            let token = res.data.shoe
            const metadataURI = token.token.contentURI || "";
           // console.log("data: " + JSON.stringify(token.token.contentURI));
            let meta = {};
            try{
                token.price = ethers.utils.formatUnits(token.price.toString(), 'ether')
                const metaData = await fetch(metadataURI)
                let response = await metaData.json() || {}
                meta = response
            } catch (err) {
                console.log(err)
            }
            return {...token, ...meta}
        }
    }





    const findItem = async (id = 1)=>{
        const query = `
         query {
         shoe(id: ${id}) {
          id
          token {
          id
          contentURI
        }
         price
         creator {
          id
        }
      }
  }`
        startWork()
        const nftDetailItem = await fetchData(query);
        setNftDetail({...nftDetailItem, ...nftDetailItem.normalData})
        finishWork();
    }

    const fetchOnSales = async () => {
        startWork()
        const onSale = await fetchMyNFTS(nftMarket, true)
        setData(onSale)
        finishWork();
    }

    const getAll = async () => {
        setLoading(true)
        await fetchOnSales()
        await fetchTokens();
        finishWork();
        setLoading(false)
    }

    const myNFTS = async (address) => {
        startWork()
        const myNFTS = await fetchMyNFTS(address)
        setMyNftsData(myNFTS)
        finishWork();
    }

    const fetchMyNFTS = async (address, lowered = true) => {
        console.log("fetchMyNFTS: ", address)
        const tokensQuery = `
         query {
         account(id: "${lowered? address.toLowerCase(): address}"){
        items { 
          id
          token {
          id
          contentURI
        }
         price
        }
    }
  }
`
        let res = await graphClient.query(tokensQuery).toPromise();
        if(res.data.account){
            const items = res.data.account.items
            let response = await Promise.all(items.map(async token => {
                const metadataURI = token.token.contentURI || ""
                let meta = {};
                try {
                    token.price = ethers.utils.formatUnits(token.price.toString(), 'ether')
                    const metaData = await fetch(metadataURI)
                    let response = await metaData.json() || {}
                    meta = response
                } catch (err) {
                }
                return {...token, ...meta}
            }))
            return response

        }
        else {
            return []
        }
    }

    const mintToken = async ({quantity, ...rest}) => {
        startWork()
        const data = JSON.stringify({...rest, quantity});
        console.log("data", data)
        try{
            const added = await client.add(data);
            const url =  `https://ipfs.infura.io/ipfs/${added.path}`;
            await createSaleToken(url, quantity);

        }catch(error){
            console.log("error: ", error)
        }
    };

    const createSaleToken = async (url, quantity) => {
        try{
            const web3Modal = new Web3Modal()
            const connection = await web3Modal.connect()
            const provider = new ethers.providers.Web3Provider(connection)
            const signer = provider.getSigner()

            /* next, create the item */
            let contract = new ethers.Contract(nft1155Adress, Token.abi, signer)
            let transaction = await contract.mint(quantity, url)
            let tx = await transaction.wait()
            let event = tx.events[0]
            let value = event.args[2]
            let tokenId = value.toNumber()
            window.alert("Created successfully")
            console.log("tokenId: ", tokenId);
            finishWork()
        } catch(err){
            if (err.data && err.data.message) {
                window.alert("Failed to create NFT: " + err.data.message)
            }
            finishWork()
        }

    }



    return {
        mint,
        buyNFT,
        uploadFileToIPFS,
        isWorking,
        fetchOnSales,
        nfts: data,
        fetchData,
        findItem,
        nftDetail,
        myNFTS, myNftsData, isBuying, mintToken, fetchTokens, tokens, transferNFT, isTransfer, transferToken, getAll, isLoading
    };
};

export default useMarket;
