import React, {useState, useContext, useEffect} from 'react';
import "../styles/style.css";
import "../styles/newstyle.css";
import Layout from "../layout/Layout.js"
import ToggleSwitch from "../components/base/toggle";
import TransferNft from "../components/TransferNft";
import {DataContext} from "../providers/MainProvider";

const Transfer = ()=> {
    const [toggle, setToggle] = useState(false);
    const {nfts, fetchOnSales, isWorking, fetchTokens, tokens, transferNFT, isTransfer, getAll, isLoading} = useContext(DataContext);
    useEffect(async () => {
       await getAll();
        // eslint-disable-next-line
    },[toggle])



    return (
        <Layout>
            <div style={{minHeight: '85%', paddingBottom: "20px"}}>
                <div className="container" >
                    {
                        isLoading? <h1>Loading</h1>:

                            <div style={{marginTop: "20px"}}>
                                <div style={{textAlign: 'center', display: 'flex',justifyContent: 'center', alignItems: 'center'}}> Market
                                    <ToggleSwitch toggle={toggle} setToggle={setToggle} /> Token</div>
                                {
                                    toggle ? <TransferNft token={true} nfts={tokens} title={"Transfer Token"} /> : <TransferNft nfts={nfts} title={"Transfer NFT"} />
                                }
                            </div>
                    }
                </div>
            </div>
        </Layout>
    );
}

export default Transfer;
