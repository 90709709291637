import React, {useState} from "react";
import "./index.css";
import classNames from 'classnames';


const ToggleButton = ( { disabled = false, className, toggle, setToggle }) => {
    const triggerToggle = () => {
        setToggle( !toggle )
    }

    const toggleClasses = classNames('wrg-toggle', {
        'wrg-toggle--checked': toggle,
        'wrg-toggle--disabled': disabled
    }, className);

    return(
        <div  onClick={triggerToggle} className={toggleClasses}>
            <div className="wrg-toggle-container">
                <div className="wrg-toggle-check">
                    <span>📈 </span>
                </div>
                <div className="wrg-toggle-uncheck">
                    <span>💎</span>
                </div>
            </div>
            <div className="wrg-toggle-circle"></div>
            <input className="wrg-toggle-input" type="checkbox" aria-label="Toggle Button" onChange={triggerToggle} />
        </div>
    )
}

export default ToggleButton;
