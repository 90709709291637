import React from 'react';
import "../../styles/style.css";
import "../../styles/newstyle.css";

const File = ({ name = "", type="text", label = "", handleChange, accept, hide=false})=> {
    return (
        <>
            {hide? null:  <label htmlFor={name}>{label}</label>}
            <input type="file" id="upload" hidden
                   accept={accept} type={type} name={name}  onChange={(e)=> handleChange(e)} id={name} className="form-control"/>
        </>
    );
}

export default File;
