import React, {useContext, useEffect} from 'react';
import "../styles/style.css";
import "../styles/newstyle.css";
import MainScreen from "../components/MainScreen"
import Layout from "../layout/Layout.js"
import {DataContext} from "../providers/MainProvider";
import {OwnerContext} from "../providers/OwnerProvider";

const Me = ()=> {
    const {myNftsData, myNFTS, isWorking} = useContext(DataContext);
    const { myAccount, getMyRole} = useContext(OwnerContext);
    useEffect(async () => {
        if(myAccount){
            await myNFTS(myAccount.address);
            await getMyRole();
        }
        // eslint-disable-next-line
    },[]);
    return (
       <Layout>
                <MainScreen nfts={myNftsData} me={true} />
       </Layout>
    );
}

export default Me;
