import React from 'react';
import "../../styles/style.css";
import "../../styles/newstyle.css";

const TextArea = ({value="", name = "", type="text", label = "", handleChange})=> {
    return (
        <>
            <label htmlFor={name}>{label}</label>
            <textarea  name={name} onChange={(e)=> handleChange(e)} id={name}
                   placeholder={label} className="form-control" value={value} rows="5"/>
        </>
    );
}

export default TextArea;
