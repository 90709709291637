import React, {useContext, useState} from 'react';
import "../styles/style.css";
import "../styles/newstyle.css";
import File from "./base/File";
import Input from "./base/Input";
import {DataContext} from "../providers/MainProvider";

const Send = ({nft, index, token})=> {
    const [files, setFiles] = useState("");
    const {transferNFT, isTransfer, transferToken} = useContext(DataContext);
    const [myNft, setNft] = useState(null);


    async function onChange(e) {
        const fileReader = new FileReader();
        fileReader.readAsText(e.target.files[0], "UTF-8");
        fileReader.onload = e => {
            setFiles(e.target.result);
        };
    }
    const transfer = async ({itemId, id, ...rest})=>{
        setNft(itemId);
        console.log("transfer", rest);
        if(files.length < 1)  {
            alert("invalid quantity of addresses");
            return;
        }
        else {
            if(token){
                // if(files.length > rest.quantity){
                //     alert("Not enough token to transfer");
                //     return;
                // }
                await transferToken(itemId, files)
            } else {
                await transferNFT(id, files)
            }

        }
    }
                   return (
                       <div className={"transfer-elements"} key={index}>
                           <div style={{width: "4vw"}}>{nft.name}</div>
                           <div className="form-group" style={{width: "20vw"}}>
                               {
                                   !token?  <div className="form-group">
                                           <Input value={files} hide={true}
                                                  label="Address" name={"files"} type="text"
                                                  handleChange={(e)=>{
                                                      setFiles(e.target.value)
                                                  }}/>
                                       </div>:
                                       <File accept={"application/json"} hide={true}
                                             label={"Upload your Json"} name={"file"} type="file"
                                             handleChange={onChange}
                                       />
                               }
                           </div>
                           <div>
                               <img style={{with: "40px", height: "40px"}} src={nft.image} alt={"no image"}/>
                           </div>
                           {
                               nft.quantity &&  <div style={{width: "5vw"}}>{nft.quantity}</div>
                           }
                           <div>
                               <button className="btn btn-danger" onClick={()=> transfer(nft)}>
                                   {isTransfer && myNft === nft.itemId? "Transferring...":"Transfer"}
                               </button></div>
                       </div>
                   )
}

export default Send;
