import React from 'react';
import "../styles/style.css";
import "../styles/newstyle.css";
import Send from "./Send.js"

const TransferNft = ({nfts = [], title = "", token= false})=> {
    console.log("nfts", nfts)
    return (
        <div>
            <div>
                {title}
            </div>

            <div className="transfer-wrapper">
                {nfts.length < 1 && <div>No NFTS</div>}
                {nfts.map((nft, index)=>(<Send nft={nft} index={index} token={token}/>))}
            </div>

        </div>
    );
}

export default TransferNft;
