import React from 'react';
import "../../styles/style.css";
import "../../styles/newstyle.css";
import {fileType} from "../../config"

const Input = ({value="", name = "", type="text", label = "", handleChange, hide= false})=> {
    return (
        <>
            {!hide && <label htmlFor={name}>{label}</label>}
            <input type={type} name={name} accept={type==="file"? fileType: ""} onChange={(e)=> handleChange(e)} id={name}
                   placeholder={label} className="form-control" value={value}/>
        </>
    );
}

export default Input;
