import React from 'react';
import "../styles/style.css";
import "../styles/newstyle.css";
import {network, mumbaiParams, mainNetParamsMatic} from "../config/index"
const Footer = ()=> {
    const importNetwork = async ()=> {
        window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [{...mumbaiParams}]
        })
            .catch((error) => {
                console.log(error)
            })
    }
    return (
        <footer id="al-footer">
            <div className="container">
                <div className="al-footer-grid2">
                    <div className="al-footer-left">
                        <a className="al-logo" href="/">
                            <img src="https://sneaker.imgix.net/https%3A%2F%2Ftrusted-trader-bucket.s3.amazonaws.com%2Fpublic%2Fnew-logo.png?w=320&auto=compress&s=1afe3bfc258be14be86fab2f5c961b23"
                                width="200px"/>
                        </a><br/>
                        <a className="insta-icon" href="https://www.instagram.com/alwayslegitny/">
                            <i className="fa fa-instagram"></i>
                        </a>
                        <p><a href="https://alwayslegit.com/contact">Contact</a></p>
                        <p><a href="https://alwayslegit.com/page/terms">Terms</a></p>
                        <p><a href="https://alwayslegit.com/page/policy">Privacy</a></p>
                        <p>
                            <button type="button" id="connectButton" style={{display: "inline-block"}} onClick={importNetwork}>
                                <img src="https://sneaker.imgix.net/https%3A%2F%2Ftrusted-trader-bucket.s3.amazonaws.com%2Fpublic%2Fmetamask-icon-2728406-2261817.png?w=30&amp;h=30&amp;s=82733da375c54283b5ca520c8ff59c3a"
                                     style={{paddingRight: "10px"}}/>
                                {`Add ${network} network`}</button>
                        </p>
                    </div>
                    <div className="al-footer-right">
                        © 2021 AlwaysLegit.com
                    </div>

                </div>
            </div>
        </footer>
    );
}

export default Footer;
