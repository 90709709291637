export const nftMarket = "0xf50e356253209d74Dc4044915AEA17d1Cb2730AA";
export const nftAddress = "0x11c7a97B024CA24FB73062681290212774d7DEcb";
export const nft1155Adress = "0x776520A4bb4739f5380B6A0767b363132f99E704"
export const rpcEndpoint = "https://polygon-mumbai.infura.io/v3/d5b54fa562cb45aba0a9fd370c3abe88"
export const fileType = "video/*"
export const posterFileType = "image/*"
export const GRAPH_URL = "https://api.thegraph.com/subgraphs/name/guillermocalderon2021/marketplace-shoes";
export const BACKUP_GRAPH = "https://thegraph.com/hosted-service/subgraph/guillermocalderon2021/marketplace-shoes"
export const network = "mumbai"

export const mumbaiParams = {
    chainId: '0x13881',
    chainName: 'Mumbai',
    nativeCurrency: {
        name: 'Matic',
        symbol: 'MATIC',
        decimals: 18
    },
    rpcUrls: ['https://rpc-mumbai.maticvigil.com/'],
    blockExplorerUrls: ['https://mumbai.polygonscan.com/']
}
export const mainNetParamsMatic = {
    chainId: '0x89',
    chainName: 'Polygon Mainnet',
    nativeCurrency: {
        name: 'Matic',
        symbol: 'MATIC',
        decimals: 18
    },
    rpcUrls: ['https://polygon-rpc.com/'],
    blockExplorerUrls: ['https://polygonscan.com/']
}

export const binanceNetwork = {
    chainId: '0x38',
    chainName: 'Binance Smart Chain',
    nativeCurrency: {
        name: 'Binance Coin',
        symbol: 'BNB',
        decimals: 18
    },
    rpcUrls: ['https://bsc-dataseed.binance.org/'],
    blockExplorerUrls: ['https://bscscan.com']
}
