import React, {useContext, useEffect, useState} from 'react';
import "../styles/style.css";
import "../styles/newstyle.css";
import Layout from "../layout/Layout.js"
import { useParams } from "react-router-dom";
import {DataContext} from "../providers/MainProvider";
import {OwnerContext} from "../providers/OwnerProvider";

const Detail = ()=> {
    const { id } = useParams();
    const {findItem,
        nftDetail, isWorking, buyNFT, isBuying} = useContext(DataContext);

    const { myAccount, getMyRole} = useContext(OwnerContext);
    useEffect(async ()=>{
        if(myAccount){
            await getMyRole(myAccount)
        }
    },[myAccount]);

console.log("Detail", nftDetail)
    const buy = (id, price)=> {
        if(isBuying) return;
        buyNFT(id, price);
    }

    useEffect(async () => {
        await findItem(id);
        // eslint-disable-next-line
    },[])

const {normalData} = nftDetail
    return (
        <Layout>
            <div style={{minHeight: '90%'}}>
                <main className="content-wrapper">
                    {
                        isWorking? <h1>Loading Details...</h1>:
                            <>
                                <div className="divIframe">

                                    <div className="containVid">
                                        <span className="closeIframe">
                                            <i className="fa fa-times-circle"></i> </span>
                                        <video className="clickVide" id="my-video" rel="prefetch" playsInline={true} autoPlay={true} loop={true} muted={true}>
                                            <source src="" type="video/mp4"/>
                                        </video>
                                    </div>
                                </div>
                                <div className="divIMGg">
                                    <div className="containIMg">
                                        <span className="closeIframe"><i className="fa fa-times-circle"></i> </span>
                                        <img className="imgFullscreen" alt="img" src=""/>
                                    </div>
                                </div>
                                <div className="listing-view-page">
                                    <div className="">
                                        <div style={{textAlign: "center"}}>
                                            <p className="information"></p>
                                        </div>
                                        <div className="listing-view">
                                            <div className="listing-left">
                                                <div className="images">
                                                    <div className="current-image" style={{position: "relative"}}>

                                                        <div className="videoContainar">
                                                            <button className="playButton"><i
                                                                className="fa fa-play" aria-hidden="true"
                                                                style={{fontSize: "50px"}}></i></button>

                                                            <video className="clickVideo" id="my-video"
                                                                   style={{display:"block", maxHeight: "300px"}}
                                                                   rel="prefetch" playsInline autoPlay loop muted
                                                                    preload="metadata">
                                                                <source
                                                                    src={nftDetail.animation_url}
                                                                    type="video/mp4"/>
                                                                    Your browser does not support the video tag.
                                                            </video>
                                                        </div>


                                                        <a className="btn pdf-btn" role="button"> <i
                                                            className="glyphicon glyphicon-download"></i> Download All
                                                            Images</a>
                                                        <ul className="image-container">

                                                            {
                                                                nftDetail &&  nftDetail.unLockable &&   nftDetail.unLockable.map((unLockable, index) => {
                                                                    return (
                                                                       <>
                                                                           <li>
                                                                               {
                                                                                   unLockable.type.includes("image")?
                                                                                       <img
                                                                                           alt={nftDetail.name}
                                                                                           src={unLockable.url}
                                                                                           className={`clickImg ${myAccount && myAccount.role === "ADMIN"? '': 'nonOwner'}`}/>:
                                                                                       <video width="320" height="240" controls={false}>
                                                                                           <source src={unLockable.url} type="video/mp4"/>
                                                                                       </video>
                                                                               }

                                                                               {
                                                                                   myAccount && myAccount.role !== "ADMIN" && (
                                                                                       <span className="questionMark">
                                                                                <img
                                                                                    src="https://sneaker.imgix.net/https%3A%2F%2Ftrusted-trader-bucket.s3.amazonaws.com%2Fpublic%2Fquestion-blue-png-removebg-preview.png?w=400&auto=compress&s=d8b2e6db5c76825bc67c98cc19cf6e59"/>
                                                                    </span>
                                                                                   )
                                                                                  }

                                                                               <button type="button"
                                                                                       className="btn pdf-btn">
                                                                                   <i className="glyphicon glyphicon-download"></i>
                                                                               </button>
                                                                           </li>
                                                                       </>
                                                                    )
                                                                })
                                                            }


                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="listing-right">
                                                <div id="alert"></div>
                                                <h1>{nftDetail.name}</h1>

                                                <a role="button" className="btn pdf-btn"
                                                   style={{display: "none"}}><i className="fa fa-file-pdf-o"></i> Download
                                                    PDF</a>

                                                <div className="description-row size">
                                                    Size: {nftDetail.size}
                                                </div>
                                                <div className="description-row sku">
                                                    Style ID: {nftDetail.styleId}
                                                </div>

                                                <div>
                                                </div>

                                                <h5 className="newH5">
                                                    <img
                                                        className='logoPrice'
                                                        src="https://sneaker.imgix.net/https%3A%2F%2Ftrusted-trader-bucket.s3.amazonaws.com%2Fpublic%2Feth-1200px-Ethereum-icon-purple.svg.png?w=30&h=30&s=d9dfe35b24c7fe989b8b49bc0157d279"
                                                        style={{width:"20px", height:"20px"}}/>{nftDetail.price}</h5>
                                                {
                                                      <h5 className="newH5" onClick={()=> buy(nftDetail.id, nftDetail.price)}>
                                                        <img
                                                            className='logoPrice'
                                                            src="https://sneaker.imgix.net/https%3A%2F%2Ftrusted-trader-bucket.s3.amazonaws.com%2Fpublic%2Fusd-coin-usdc-logo.png?w=30&h=30&s=f7d83e1d7a68e7c92aa2a25585c3876a"
                                                            width="20" height="20"/> {isBuying? "Buying...": "Buy"}</h5>
                                                }


                                                <div>
                                                </div>

                                                <div className="hide-me description-row product_id">
                                                    <div className="column"> Product Id: {nftDetail.id}</div>
                                                </div>


                                                <div className="description-row condition">
                                                    <h3> Condition: </h3>
                                                    <p>{`${nftDetail.size} ${nftDetail.manufacturer}/${nftDetail.model}`}</p>
                                                </div>

                                                <div style={{display:" none", marginTop: "40px",textAlign: "center"}}>
                                                    <p>
                                                        BUYER PROTECTION - All products are 100% Legit and authenticated
                                                        directly by our expert team. You are 100% protected and you can
                                                        return your product in the same condition within 14 days for
                                                        site credit.
                                                    </p>
                                                    <p>
                                                        * EXCLUSIVE OFFER - FREE SHIPPING FOR BUYERS *
                                                    </p>
                                                </div>

                                                <div style={{display:"block",margin:"30px 0"}}
                                                     className="socializer a sr-32px sr-opacity sr-icon-white sr-pad">
                                                    <strong
                                                        style={{display: "block",width: "100%",fontSize: "14px",margin: "0 0 6px 0"}}>Share
                                                        With Your Friends</strong>
                                                    <span className="sr-facebook">
                            <a style={{display: "inline-block",padding:" 4px 4px",fontSize: "20px"}}
                               href="https://www.facebook.com/share.php?u=https%3A%2F%2Fwww.alwayslegit.com%2Flisting%2Fview-web3c%2F8179&amp;t=Jordan%201%20Retro%20High%20Union%20Los%20Angeles%20Blue%20Toe"
                               target="_blank" title="Facebook">
                                <i style={{width: "auto", height: "auto",boxShadow:"none"}} className="fa fa-facebook-f"></i>
                            </a>
                        </span>
                                                    <span className="sr-twitter">
                            <a style={{display: "inline-block",padding:" 4px 4px",fontSize: "20px"}}
                               href="https://twitter.com/intent/tweet?text=Jordan%201%20Retro%20High%20Union%20Los%20Angeles%20Blue%20Toe%20-%20https%3A%2F%2Fwww.alwayslegit.com%2Flisting%2Fview-web3c%2F8179"
                               target="_blank" title="Twitter">
                                <i style={{width: "auto", height: "auto",boxShadow:"none"}} className="fa fa-twitter"></i>
                            </a>
                        </span>
                                                    <span className="sr-pinterest">
                            <a style={{display: "inline-block",padding:" 4px 4px",fontSize: "20px"}}
                               href="https://www.pinterest.com/pin/create/button/?url=https%3A%2F%2Fwww.alwayslegit.com%2Flisting%2Fview-web3c%2F8179&amp;media=&amp;description=Jordan%201%20Retro%20High%20Union%20Los%20Angeles%20Blue%20Toe"
                               target="_blank" title="Pinterest">
                                <i style={{width: "auto", height: "auto",boxShadow:"none"}} className="fa fa-pinterest"></i>
                            </a>
                        </span>
                                                    <span className="sr-email">
                            <a style={{display: "inline-block",padding:" 4px 4px",fontSize: "20px"}}
                               href="mailto:?subject=Jordan%201%20Retro%20High%20Union%20Los%20Angeles%20Blue%20Toe&amp;body=%20-https%3A%2F%2Fwww.alwayslegit.com%2Flisting%2Fview-web3c%2F8179"
                               target="_blank" title="Email">
                                <i style={{width: "auto", height: "auto",boxShadow:"none"}} className="fa fa-envelope"></i>
                            </a>
                        </span>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </>

                    }

                        </main>
            </div>
        </Layout>
    );
}

export default Detail;
