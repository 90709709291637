import { useState, useContext } from "react";
import {DataContext} from "../providers/MainProvider";
import { hasRequiredKeysToken} from "../helpers";

const defaultValues = {price: '', name: '', quantity: "", description: ""}
const useForm = (defaultValue = defaultValues) => {
    const [data, setData] = useState(defaultValue);
    const [uploadingFile, setUploadingFile] = useState(false);
    const [uploadingPoster, setUploadingPoster] = useState(false);
    const {mintToken, uploadFileToIPFS, isWorking} = useContext(DataContext)

    const handleChange = (e)=> {
        let payload = {}
        payload = {
            ...data, [e.target.id]: e.target.value
        }
        setData(payload);
    }


    const sendFile = async (file) => {
        setUploadingFile(true)
        //console.log("file: " + JSON.stringify(file))
        const url =  await uploadFileToIPFS(file);
        console.log("url", url);
        setData({...data, animation_url: url});
        setUploadingFile(false)
    }

    const sendPoster = async (file) => {
        setUploadingPoster(true)
        console.log("file: " + JSON.stringify(file))
        const url =  await uploadFileToIPFS(file);
        console.log("url", url);
        setData({...data, image: url});
        setUploadingPoster(false)
    }



    const onSubmit = async ()=> {
        if(hasRequiredKeysToken(data)){
            if(!(/^[0-9]*\.?[0-9]*$/.test(data.price) && data.price !== '.')){
                window.alert("No valid Price")
            } else {
                await mintToken(data);
                cleanData();
            }
        }else {
            window.alert("you should add all the fields")
        }

    }

    const setAllData = (data)=> {
        setData(data);
    }

    const cleanData = () => {
        setData(defaultValues)
    }

    return {
        data,
        handleChange,
        sendFile,
        onSubmit,
        cleanData,
        isWorking,
        uploadingFile,
        setAllData,
        uploadingPoster,
        sendPoster
    };
};

export default useForm;
