import React, {useContext, useEffect, useState} from 'react';
import "../styles/style.css";
import "../styles/newstyle.css";
import Layout from "../layout/Layout.js"
import useForm from "../hooks/useForm";
import Input from "../components/base/Input";
import {OwnerContext} from "../providers/OwnerProvider";

const Admin = ()=> {
    const {addMinter, remove, addAdmin, accounts, fetchRoles, isWorking,  isRemoving, isSaving} = useContext(OwnerContext);
    const [selectedItem, setSelected] = useState(null);
    useEffect(async () => {
        await fetchRoles();
        // eslint-disable-next-line
    },[])
    const {data, handleChange, cleanData} = useForm({address: ""});
    const handleSubmit = ()=> {
        if(isSaving) return;
      if(!data.address){
          alert("Please enter a valid address")
          return
      } else {
          if(data.role === "Admin"){
              addAdmin(data.address)
          } else if(data.role === "Minter"){
              addMinter(data.address)
          } else {
              alert("pick a role")
          }
      }
    }

    const removeRole = async (address)=> {
        if(address.role ==="ADMIN"){
            window.alert("You cannot remove administration role");
            return;
        } else if(address.role ==="MINTER"){
            setSelected(address.id);
            await remove(address)
        }
        // const myTimeout = setTimeout(fetchRoles, 50000);
        // clearTimeout(myTimeout);
    }
    const accountFiltered = accounts.filter(account => account.role !== null);
    return (
       <Layout>
                <div style={{minHeight: '85%'}}>
                  <div className="container">
                      <table className="table" style={{ marginTop: '5%'}}>
                          <thead>
                          <tr>
                              <th scope="col">#</th>
                              <th scope="col">Adress</th>
                              <th scope="col">Role</th>
                              <th scope="col">Eliminar</th>
                          </tr>
                          </thead>
                          {
                              isWorking ? <h1>Loading</h1>:
                                  <tbody>
                                  {
                                      accountFiltered.map((address, index)=> (
                                          <tr key={index}>
                                              <th scope="row">{index}</th>
                                              <td>{address.id}</td>
                                              <td>{address.role}</td>
                                              <td>
                                                  <button className="btn btn-danger" onClick={async()=> {await removeRole(address)}}>
                                                      {isRemoving && selectedItem=== address.id? "Removing" : "Remove"}
                                                  </button>
                                              </td>
                                          </tr>
                                      ))
                                  }
                                  </tbody>
                          }
                      </table>
                      <div>
                          <div className="form-row">
                              <div className="form-group col-md-6">
                                  <Input value={data["address"]}
                                         label="Address" name={"address"} type="text"
                                         handleChange={handleChange}
                                  />
                              </div>
                              <div className="form-group col-md-6">
                                  <label htmlFor="inputState">Role</label>
                                  <select id="role" className="form-control" value={data["role"]} onChange={handleChange}>
                                      <option defaultValue value={""}>Choose...</option>
                                      <option value={"Admin"}>Admin</option>
                                      <option value={"Minter"}>Minter</option>
                                  </select>
                              </div>
                          </div>
                          <div className="flex">
                              <button type="submit" className="btn button-opentype" onClick={()=>{handleSubmit()}}>{isSaving? "Saving...": "Save"}</button>
                              <button type="submit" className="btn button-opentype" onClick={()=> cleanData()}>Clean</button>
                          </div>
                      </div>
                  </div>
                </div>
       </Layout>
    );
}

export default Admin;
