import React from 'react';
import "../styles/style.css";
import "../styles/newstyle.css";
import Footer from "../layout/Footer.js"
import Push from "../components/Push"
import Header from "../layout/Header.js"

const Home = ({children})=> {
    return (
        <div className="app">
            <div id="wrapper">
                <Header/>
                {children}
            <Push/>
            <Footer/>
            </div>
        </div>
    );
}

export default Home;
